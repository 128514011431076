<template>
	<modal name="ClvModalCurrencyPairs" class="ClvModalCurrencyPairs" id="ClvModalCurrencyPairs" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen" @before-close="beforeClose">
		<div class="clv_modal">
			<div class="clv_modal_head">
				<div class="clv_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="clv_modal_title">
					<span v-if="clvType == 'addComment'" v-html="$store.getters.getLanguageText('5.1', 0)"></span>
					<span v-else-if="clvType == 'getCLVHelp'" v-html="$store.getters.getLanguageText('5.1', 11)"></span>
					<span v-else-if="clvType == 'stopVoting'" v-html="$store.getters.getLanguageText('5.1', 22)"></span>
				</div>
			</div>
			<div class="clv_modal_body">
				<div class="clv_modal_info">
					<div v-if="clvType == 'addComment'">
						<p v-if="clvId == 1" v-html="$store.getters.getLanguageText('5.1', 1, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<p v-else-if="clvId == 2" v-html="$store.getters.getLanguageText('5.1', 2, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<p v-else-if="clvId == 3" v-html="$store.getters.getLanguageText('5.1', 3, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase(), value: parseFloat($parent.clvCurrencyPairs[clvIndex].slider_value).toFixed(2) })"></p>
						<p v-else-if="clvId == 4" v-html="$store.getters.getLanguageText('5.1', 4, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<p v-else-if="clvId == 5" v-html="$store.getters.getLanguageText('5.1', 5, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<p v-else-if="clvId == 6" v-html="$store.getters.getLanguageText('5.1', 6, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<p v-else-if="clvId == 7" v-html="$store.getters.getLanguageText('5.1', 7, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<p v-else-if="clvId == 8" v-html="$store.getters.getLanguageText('5.1', 8, { symbol: $parent.clvCurrencyPairs[clvIndex].index.toUpperCase() })"></p>
						<div class="clv_modal_info_row last">
							<div class="clv_modal_info_row_label" v-html="$store.getters.getLanguageText('5.1', 9)"></div>
							<div class="clv_modal_info_row_value">
								<textarea class="clv_textarea small" v-model.trim="clvComment" :placeholder="$store.getters.getLanguageText('5.1', 10)"></textarea>
								<div v-html="clvAlertComment"></div>
							</div>
						</div>
					</div>
					<div v-else-if="clvType == 'getCLVHelp'">
						<div class="clv_modal_info_CLVHelp">
							<vue-easy-lightbox class="clv_lightbox" :index="clvLightboxIndex" :imgs="clvLightboxImages" :visible="clvIsLightboxVisible" @hide="hideLightbox"></vue-easy-lightbox>
							<div class="clv_modal_info_CLVHelp_text" v-html="$store.getters.getLanguageText('5.1', 12)"></div>
							<div class="clv_modal_info_CLVHelp_text"><img src="@/assets/images/CLVHelp1.png" @click="showLightbox(0, [require(`@/assets/images/CLVHelp1.png`)])"><div v-html="$store.getters.getLanguageText('5.1', 13)"></div></div>
							<div class="clv_modal_info_CLVHelp_text" v-html="$store.getters.getLanguageText('5.1', 14)"></div>
							<div class="clv_modal_info_CLVHelp_text"><img src="@/assets/images/CLVHelp2.png" class="image_left" @click="showLightbox(0, [require(`@/assets/images/CLVHelp2.png`)])"><div v-html="$store.getters.getLanguageText('5.1', 15)"></div></div>
							<div class="clv_modal_info_CLVHelp_text"><img src="@/assets/images/CLVHelp3.png" class="image_left" @click="showLightbox(0, [require(`@/assets/images/CLVHelp3.png`)])"><div v-html="$store.getters.getLanguageText('5.1', 16)"></div></div>
							<div class="clv_modal_info_CLVHelp_text"><img src="@/assets/images/CLVHelp4.png" class="image_left" @click="showLightbox(0, [require(`@/assets/images/CLVHelp4.png`)])"><div v-html="$store.getters.getLanguageText('5.1', 17)"></div></div>
							<div class="clv_modal_info_CLVHelp_text"><img src="@/assets/images/CLVHelp5.png" @click="showLightbox(0, [require(`@/assets/images/CLVHelp5.png`)])"></div>
							<div class="clv_modal_info_CLVHelp_text" v-html="$store.getters.getLanguageText('5.1', 18)"></div>
							<div class="clv_modal_info_CLVHelp_text" v-html="$store.getters.getLanguageText('5.1', 19)"></div>
							<div class="clv_modal_info_CLVHelp_text"><img src="@/assets/images/CLVHelp6.png" @click="showLightbox(0, [require(`@/assets/images/CLVHelp6.png`)])"></div>
							<div class="clv_modal_info_CLVHelp_text" v-html="$store.getters.getLanguageText('5.1', 20)"></div>
							<div class="clv_modal_info_CLVHelp_text" v-html="$store.getters.getLanguageText('5.1', 21)"></div>
						</div>
					</div>
					<div v-else-if="clvType == 'stopVoting'" v-html="$store.getters.getLanguageText('5.1', 23)"></div>
				</div>
				<div class="clv_modal_alert" v-html="clvAlert"></div>
				<div class="clv_modal_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_modal_buttons" :class="{ hide: clvIsLoading }">
					<button type="button" class="clv_button common" @click="close" v-if="clvType == 'addComment'" v-html="$store.getters.getLanguageText('1.1', 9)"></button>
					<button type="button" class="clv_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="clv_button common green" @click="addComment" v-if="clvType == 'addComment'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="clv_button common red" @click="axios" v-else-if="clvType == 'stopVoting'" v-html="$store.getters.getLanguageText('1.1', 20)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvAlert: '',
			clvAlertComment: '',
			clvId: 0,
			clvType: '',
			clvIndex: 0,
			clvComment: '',
			clvLightboxIndex: 0,
			clvLightboxImages: [],
			clvIsLightboxVisible: false,
			clvIsAddComment: false,
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.clvTemp = '';
				this.resetAlerts();
				this.clvId = event.params.id;
				this.clvType = event.params.type;
				this.clvIndex = event.params.index;
				this.clvComment = '';
				this.clvLightboxIndex = 0;
				this.clvLightboxImages = [];
				this.clvIsLightboxVisible = false;
				this.clvIsAddComment = false;
				this.clvIsLoading = false;
				this.clvIsError = false;
			},
			beforeClose: function() {

				if (this.clvType == 'addComment') {
					if (!this.clvIsAddComment) {
						if ([1,2].includes(this.clvId)) {
							this.$parent.clvCurrencyPairs[this.clvIndex].trend_status = this.$parent.clvCurrencyPairs[this.clvIndex].trend_status_temp;
							this.$parent.clvCurrencyPairs[this.clvIndex].trend_status_comment = '';
						}
						else if (this.clvId == 3) {
							this.$parent.currencyPairsRangeChange(this.clvIndex, this.$parent.clvCurrencyPairs[this.clvIndex].slider_value_temp);
							this.$parent.clvCurrencyPairs[this.clvIndex].slider_value_comment = '';
						}
						else if (this.clvId == 4) {
							this.$parent.clvCurrencyPairs[this.clvIndex].multiplier = this.$parent.clvCurrencyPairs[this.clvIndex].multiplier_temp;
							this.$parent.clvCurrencyPairs[this.clvIndex].multiplier_comment = '';
						}
						else if (this.clvId == 5) {
							this.$parent.clvCurrencyPairs[this.clvIndex].buy_trading = this.$parent.clvCurrencyPairs[this.clvIndex].buy_trading_temp;
							this.$parent.clvCurrencyPairs[this.clvIndex].buy_trading_comment = '';
						}
						else if (this.clvId == 6) {
							this.$parent.clvCurrencyPairs[this.clvIndex].buy_closing = this.$parent.clvCurrencyPairs[this.clvIndex].buy_closing_temp;
							this.$parent.clvCurrencyPairs[this.clvIndex].buy_closing_comment = '';
						}
						else if (this.clvId == 7) {
							this.$parent.clvCurrencyPairs[this.clvIndex].sell_trading = this.$parent.clvCurrencyPairs[this.clvIndex].sell_trading_temp;
							this.$parent.clvCurrencyPairs[this.clvIndex].sell_trading_comment = '';
						}
						else if (this.clvId == 8) {
							this.$parent.clvCurrencyPairs[this.clvIndex].sell_closing = this.$parent.clvCurrencyPairs[this.clvIndex].sell_closing_temp;
							this.$parent.clvCurrencyPairs[this.clvIndex].sell_closing_comment = '';
						}
					}
				}
			},
			resetAlerts: function() {

				this.clvAlert = '';
				this.clvAlertComment = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = this.$store.getters.getLanguageText('1.3.2', 2); }
				else if (i == 2) { this.clvTemp = this.$store.getters.getLanguageText('1.3.2', 3); }
				else if (i == 3) { this.clvTemp = this.$store.getters.getLanguageText('1.3.2', 4); }
				else if (i == 4) { this.clvTemp = this.$store.getters.getLanguageText('1.3.2', 5); }
				else             { this.clvTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.clvTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (_this.clvType == 'stopVoting') {
						config.url = '/v2/account/users/is_voting';
						config.data = { is_voting: 0 };
						config.method = 'patch';
					}

					if (!_this.clvIsError) {
						_this.clvIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.clvType == 'stopVoting') {
									_this.$set(_this.$store.state.clvAccountData, 'is_voting', 0);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.clvType == 'stopVoting') {
										if      (data.response.data.error == 'IS_VOTING_IS_EMPTY') { _this.clvAlert = _this.getError(3); }
										else if (data.response.data.error == 'IS_VOTING_NOT_VALID') { _this.clvAlert = _this.getError(4); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
							}
							if (!_this.clvIsError) {
								_this.clvAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.clvIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			addComment: function() {

				this.resetAlerts();
				this.clvIsAddComment = false;
				this.clvIsError = false;

				if (!this.clvComment) {
					this.clvAlertComment = this.getError(1);
				}
				else if (!this.$store.state.clvRegexENRULettersAndNumbersAndMarks.test(this.clvComment) || this.clvComment.length > 1000) {
					this.clvAlertComment = this.getError(2);
				}
				if (!this.clvIsError) {
					if ([1,2].includes(this.clvId)) {
						this.$parent.clvCurrencyPairs[this.clvIndex].trend_status_temp = this.$parent.clvCurrencyPairs[this.clvIndex].trend_status;
						this.$parent.clvCurrencyPairs[this.clvIndex].trend_status_comment = this.clvComment;
					}
					else if (this.clvId == 3) {
						this.$parent.clvCurrencyPairs[this.clvIndex].slider_value_temp = this.$parent.clvCurrencyPairs[this.clvIndex].slider_value;
						this.$parent.clvCurrencyPairs[this.clvIndex].slider_value_comment = this.clvComment;
					}
					else if (this.clvId == 4) {
						this.$parent.clvCurrencyPairs[this.clvIndex].multiplier_temp = this.$parent.clvCurrencyPairs[this.clvIndex].multiplier;
						this.$parent.clvCurrencyPairs[this.clvIndex].multiplier_comment = this.clvComment;
					}
					else if (this.clvId == 5) {
						this.$parent.clvCurrencyPairs[this.clvIndex].buy_trading_temp = this.$parent.clvCurrencyPairs[this.clvIndex].buy_trading;
						this.$parent.clvCurrencyPairs[this.clvIndex].buy_trading_comment = this.clvComment;
					}
					else if (this.clvId == 6) {
						this.$parent.clvCurrencyPairs[this.clvIndex].buy_closing_temp = this.$parent.clvCurrencyPairs[this.clvIndex].buy_closing;
						this.$parent.clvCurrencyPairs[this.clvIndex].buy_closing_comment = this.clvComment;
					}
					else if (this.clvId == 7) {
						this.$parent.clvCurrencyPairs[this.clvIndex].sell_trading_temp = this.$parent.clvCurrencyPairs[this.clvIndex].sell_trading;
						this.$parent.clvCurrencyPairs[this.clvIndex].sell_trading_comment = this.clvComment;
					}
					else if (this.clvId == 8) {
						this.$parent.clvCurrencyPairs[this.clvIndex].sell_closing_temp = this.$parent.clvCurrencyPairs[this.clvIndex].sell_closing;
						this.$parent.clvCurrencyPairs[this.clvIndex].sell_closing_comment = this.clvComment;
					}
					this.clvIsAddComment = true;
					this.close();
				}
			},
			showLightbox: function(index, images) {
				this.clvLightboxIndex = index;
				this.clvLightboxImages = images;
				this.clvIsLightboxVisible = true;
			},
			hideLightbox: function() {
				this.clvIsLightboxVisible = false;
			},
			close: function() {
				this.$modal.hide('ClvModalCurrencyPairs');
			}
		}
	}
</script>
